import { useMemo } from 'react';
import PropTypes from 'prop-types';
import '/assets/styles/partials/pricebox.scss';

const PriceBox = ({ product, showTotal, showOldTotal, showCurrency, big, className: classNameProp, price: priceProp, oldPrice: oldPriceProp, unit }) => {
	const { oldPrice, price, priceBase, priceDecimal, className, currency, isNegative } = useMemo(() => {
		let price = false;
		let isNegative = false;
		let oldPrice = false;

		if(product) {
			if(showTotal) {
				isNegative = product?.total_price?.original < 0;
				price = product.total_price?.original_str.replace('-', '');

				if(showOldTotal) {
					if (product.total_price_before_campaigns?.original && product.total_price_before_campaigns?.original > 0 && product.total_price_before_campaigns.original > product.total_price.original) {
						oldPrice = product.total_price_before_campaigns.original_str;
					}
				}
			}
			else {
				isNegative = product?.total_price?.original < 0;
				price = product.price?.original_str;
	
				if(product.old_price) {
					if(typeof product.old_price === 'string') {
						const oldPriceNum = parseFloat(product.old_price.replace(',', '.'));

						if(oldPriceNum > product.price.original) {
							oldPrice = oldPriceNum.toFixed(2).replace('.', ',');
						}
					}
					else if (product.old_price?.original && product.old_price?.original > 0 && product.old_price.original > product.price.original) {
						oldPrice = product.old_price.original_str.replace('-', '');
					}
				}
			}
		}
		else {
			if(typeof priceProp === 'string') {
				isNegative = priceProp.includes('-');
				price = priceProp.replace('-', '');
			}
			else if(typeof priceProp === 'number') {
				isNegative = priceProp < 0;
				price = priceProp.toString().replace('-', '').replace('.', ',');
			}
			else if(priceProp) {
				isNegative = priceProp?.original < 0;
				price = priceProp?.original_str ? priceProp.original_str.replace('-', '') : priceProp;
			}

			if(oldPriceProp) {
				if(typeof oldPriceProp === 'string') {
					oldPrice = oldPriceProp.replace('-', '');
				}
				else if(typeof oldPriceProp === 'number') {
					isNegative = oldPriceProp < 0;
					price = oldPriceProp.toString().replace('-', '').replace('.', ',');
				}
				else {
					oldPrice = oldPriceProp?.original_str.replace('-', '') ?? oldPriceProp;
				}
			}
		}
		
		const [priceBase, priceDecimal] = price ? price.split(',') : [false, false];

		return {
			className: `pricebox${big ? ' big' : ''} ${classNameProp}`,
			currency: showCurrency ? 'TL' : false,
			isNegative,
			price,
			priceBase,
			priceDecimal,
			oldPrice
		}
	}, [product, showTotal, classNameProp, showCurrency, priceProp]);

	return price ? (
		<span className={className}>
			{oldPrice &&
				<span className="pricebox-old">
					{isNegative && '-'}{oldPrice}{currency && <span className="pricebox-currency">{currency}</span>}
				</span>
			}
			<span className="pricebox-main">
				{isNegative && '-'}
				{big ? <><span className="main-base">{priceBase}</span><span className="main-decimal">,{priceDecimal}</span></> : price}{currency && <span className="pricebox-currency">{currency}</span>}{unit && <span className="pricebox-unit">/{unit}</span>}
			</span>
		</span>
	) : null
}

PriceBox.defaultProps = {
	className: '',
	showCurrency: true,
	showTotal: false,
	big: false,
}

PriceBox.propTypes = {
	className: PropTypes.string,
	product: PropTypes.object,
	price: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
	oldPrice: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
	showCurrency: PropTypes.bool,
	showTotal: PropTypes.bool,
	showOldTotal: PropTypes.bool,
	big: PropTypes.bool,
	unit: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
}

export default PriceBox