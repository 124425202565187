import '/assets/styles/partials/new-badge.scss';
import PropTypes from 'prop-types';

export const NewBadge = ({ className }) => {
	return (
		<div className={`new-badge ${className ?? ''}`}>
			Yeni
		</div>
	)
}

NewBadge.propTypes = {
	className: PropTypes.string,
}

export default NewBadge;